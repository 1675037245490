/* Header styling for all pages */
.App-header {
  background-color: #ffce00;
  color: #011722;
  padding: 100px 20px;
  text-align: center;
}

.App-logo {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-bottom: -20px;
  margin-left: -650px;
  margin-top: -50px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-top: -150px;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Navigation styles */
.nav-container {
  width: 100%; /* Full width to align with header */
  background-color: #ffce00; /* Matches header background */
  text-align: center; /* Center aligns the buttons */
  margin-bottom: 60px; /* Adds spacing below the buttons */
  margin-top: -100px;
}

.nav {
  display: inline-flex; /* Inline layout for buttons */
  gap: 30px; /* Adds space between individual button boxes */
  padding: 15px 0; /* Padding for the entire navigation area */
}

.nav-button {
  display: inline-block; /* Ensures each button is treated as a block element */
  text-decoration: none;
  color: #011722; /* Matches the header text color */
  font-size: 18px; /* Bigger font for buttons */
  font-weight: bold;
  padding: 15px 20px; /* Adds space inside the box */
  background-color: #fff; /* Box background color */
  border: 2px solid #011722; /* Box border color */
  border-radius: 10px; /* Rounded corners for the boxes */
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
}

.nav-button:hover {
  background-color: #007BFF; /* Changes background color on hover */
  color: #fff; /* Changes text color on hover */
  transform: scale(1.05); /* Slight enlargement on hover */
}
