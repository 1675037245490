/* src/pages/Wwu.css */

/* General Reset to avoid margin/padding issues */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Work with Us page styles */
#about {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-left: 40px;
}

.wwu-heading {
  color: black;
  font-size: 40px;
  margin-bottom: 15px;
}

.text-content,
.wwu-second-text-content,
.wwu-third-text-content,
.wwu-fourth-text-content,
.wwu-fifth-text-content,
.wwu-sixth-text-content {
  font-size: 35px;
  line-height: 1.6;
  margin-bottom: 0px;
}

.text-content h2,
.wwu-second-text-content h2,
.wwu-third-text-content h2,
.wwu-fourth-text-content h2,
.wwu-fifth-text-content h2,
.wwu-sixth-text-content h2 {
  font-size: 35px;
  margin-bottom: 10px;
}

.text-content p,
.wwu-second-text-content p,
.wwu-third-text-content p,
.wwu-fourth-text-content p,
.wwu-fifth-text-content p,
.wwu-sixth-text-content p {
  margin-bottom: 10px;
}

ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 0px;
}

a {
  color: blue;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Adjust layout on smaller screens */
@media screen and (max-width: 768px) {
  .text-content,
  .wwu-second-text-content,
  .wwu-third-text-content,
  .wwu-fourth-text-content,
  .wwu-fifth-text-content,
  .wwu-sixth-text-content {
    font-size: 14px;
  }

  .text-content h2,
  .wwu-second-text-content h2
  .wwu-third-text-content h2,
  .wwu-fourth-text-content h2,
  .wwu-fifth-text-content h2,
  .wwu-sixth-text-content h2 {
    font-size: 20px;
  }
}

  h3 {
  padding-bottom: 10px;
  padding-top: 50px;
}