/* src/pages/Home.css */

.home-heading {
  color: black;
}

.App-section {
  padding: 20px;
}

.home-text-image-container {
  display: flex;
  align-items: center; /* Vertically align text and image */
  gap: 20px; /* Adjust the gap between text and image */
  margin: 50px 0px;
}


.home-text-content {
  flex: 1;
  padding-right: 10px;
  margin-top: -600px;
}

.franky-image {
  width: 200px;
  height: auto;
  align-self: center; /* Keep image centered with text */
  margin: 200px 0; /* Remove margin around the image */
}

.home-second-text-content {
  flex: 1;
  margin-top: -650px; /* Remove any negative margin */
}

.home-third-text-content {
  flex: 1;
  margin: 0;
}

.home-fourth-text-content {
  flex: 1;
  margin: 0;
}

.home-fifth-text-content {
  flex: 1;
  margin: 0;
}