/* About Us Page Layout */
.about-us-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    padding: 50px 100px;
  }
  
  .about-us-section {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 20px 0;
  }
  
  .about-us-image {
    width: 250px; /* Adjust width as needed */
    height: auto;
    margin-right: 50px; /* Space between image and text */
  }
  
  .about-us-text {
    max-width: calc(100% - 220px); /* Adjust based on image width and margin */
    text-align: left;
  }

  .about-us-heading {
    color: black
  }

  .ceo-text {
    color: black;
    text-align: justify;
    font-size: 20px;
    line-height: 25px;
  }