/* App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Top navigation bar */
.App-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: false;
  color: white;
  padding: 10px 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

/* Section styling */
.App-section {
  padding: 20px;
  margin: 20px;
  text-align: left;
}

/* Heading styling for subsections */
h2 {
  font-size: 2rem;
  color: black;
  margin-bottom: 10px;
}

/* List styling */
ul, ol {
  font-size: 1rem;
  line-height: 1.8;
  text-align: left;
  margin: 0 auto;
  max-width: 800px;
}

ul li, ol li {
  margin-bottom: 10px;
}

strong {
  color: #008080;
}

/* Link styling */
a {
  color: #008080;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Make sure content doesn't overlap with the fixed navbar */
body {
  margin-top: 0px;
}