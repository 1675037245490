/* Footer styling */
.App-footer {
  background-color: black;
  color: white;
  padding: 20px;
  font-size: 1rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Contact information styling */
.App-footer .contact-info {
  display: flex;
  flex-direction: column;
}

.App-footer .contact-info p {
  margin: 0px 50px;
}

.App-footer .contact-info h2 {
  margin: 0px 50px;
}

/* Logo styling */
.App-footer .logo {
  margin-right: 20px; /* Add spacing from the right edge */
}

.App-footer .logo img {
  max-width: 150px; /* Adjust size of the logo */
  height: auto;
}

/* Heading styling for subsections */
h2 {
  font-size: 2.3rem;
  color: white;
  margin-bottom: 10px;
}
